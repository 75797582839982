.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}
body {
  font-size: 12px;
}
.container-fluid {
  /* background-color: #f0f0f0 !important; */

}

.button {
  height: 40px;
  width: 100px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.button:hover {
  background-color: #84c586;
  color: whitesmoke;
  border-color: gray;
  transform: scale(1.05);
}
.form__controls {
  margin: 10px;
  padding: 2px;
}
.form__controls > label {
  margin-right: 10px;
  width: 100px;
  height: 30px;
}
.form__controls > input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  transition: 0.1s ease-in-out;
}
.form__controls > input:focus {
  outline: 2px solid #84c586;
  outline-offset: 1px;
  transform: scale(1.05);
}
.footer{
  background-color: #f0f0f0 !important;
}
.form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  width: 450px;
}
@media only screen and (max-width: 480px) {
  .form__container {
    width: 290px;
  }
  .form__controls > input {
    width: 150px;
  }
}
.bg {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.dashboard {
  background: #fff;
  width: 100%;
  height: 100%;
}
header {
  height: 60px;
}
.userProfile {
  /* height: 300px; */
}
footer {
  height: 60px;
  background: #fff;
}
.small {
  font-size: 12px;
}
.crousal-img {
   margin: 0;
  height: 180px;
  border-radius: 20px;
 
}
.carousel {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 20px;
}
.action-group {
  padding: 0 0px;
  border: 1px solid #ccc;
  border-radius: 50px;
  height:100px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  /* background-color: #999; */
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content:space-around;
}
.action-btn{
  padding: 10px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  
}
.action-btn:active{

  align-items: center;
  text-align: center;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
  
}
.btn-back{
cursor: pointer;
}
.btn-back:active{
  padding: 10px;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}

.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .qrVideo {
    object-fit: cover;
    border: 1px solid #ddd;
    width: 350px;
    height: 350px;
    border-radius: 50px;
  }
}

.scannedText {
  word-wrap: break-word;
}
.not-verified{
  background-color: coral;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.verified{
  background-color:#84c586;
  border-radius: 10px;
}
.text-right{
  text-align: right;
}
.bold{
  font-weight: bold;
}